export const BILLING_REPORT_HEADERS = [
  { label: 'Identify', key: 'memberName' },
  { label: 'MRN', key: 'externalMrn' },
  { label: 'Employee ID', key: 'externalEmployeeId' },
  { label: 'Band ID', key: 'bandId' },
  { label: 'Onboarded', key: 'icmOnboardDate' },
  { label: 'Reporting Month', key: 'month' },
  { label: 'Reporting Year', key: 'year' },
  { label: 'Account Age (d)', key: 'daysSinceOnboard' },
  { label: 'Band Compliance', key: 'totalDaysBandSynced' },
  { label: 'CTM ID', key: 'ctmId' },
  {
    label: 'CTM Time',
    key: 'careMemberTotalMinutesSpent',
  },
];

export const PDF_DEFINITIONS = [
  {
    name: 'First Name',
    definition: 'First name of individual patient under care by Care Team.',
  },
  {
    name: 'Last Name',
    definition: 'Last name of individual patient under care by Care Team.',
  },
  {
    name: 'User ID',
    definition: 'Unique ID assigned to each Member in the system.',
  },
  {
    name: 'MRN',
    definition: 'Medical Record Number.',
  },
  {
    name: 'Account Status',
    definition: 'Status of the member account in the system.',
  },
  {
    name: 'Account Created',
    definition:
      'Date at which the Member was created upon registration or when order was received.',
  },
  {
    name: 'Member Since',
    definition:
      'Date at which the Member was first activated in the system, syncs the band for the first time and post ticks.',
  },
  {
    name: 'Account Age',
    definition: 'Length of time (since onboarding) Member has been active in the system.',
  },
  {
    name: 'Band Compliance',
    definition: 'Number of days within reporting date range the member wore the band.',
  },
  {
    name: '16d Req',
    definition:
      '16-day requirement met. Indicates if Member met the 16/30 days data submitted requirement for the reporting period.',
  },
  {
    name: 'Care Team',
    definition: 'Clinicians assigned to Member.',
  },
  {
    name: 'QHCP',
    definition:
      'Qualified Healthcare Professionals. Providers of healthcare services that have a National Provider Index individual number and are able to bill for services they and their supervised team provide.',
  },
  {
    name: 'CTM Time',
    definition:
      'Care Team Member Time. Amount of time (minutes) individual Care Team Members spent on Member screens in system.',
  },
  {
    name: 'Total Time',
    definition:
      'Care Team Total Time. Amount of time the Care Team spent on Member screens in system.',
  },
];

export const BILLING_PRINT_PER_PAGE = 17;

export const SOURCES: { value: any; label: string }[] = [
  {
    value: 'patient',
    label: 'Patient',
  },
  {
    value: 'panel',
    label: 'Panel',
  },
  {
    value: 'tenant',
    label: 'Tenant',
  },
];

export const TOTAL_PAGES = 10;
