import { useCallback, useEffect } from 'react';
import Sb, { SendBirdInstance, GroupChannel, OpenChannel } from 'sendbird';
import { isEmpty } from 'lodash';

import { Notification } from 'models/notification';
import { format } from 'date-fns';
import { registryApi } from 'services/registry';
import { sdkSelector } from 'store/messaging/sdk';
import { AppDispatch, useAppSelector, useAppDispatch } from 'store/index';
import { v4 } from 'uuid';
import { tokenParsed } from 'store/auth';
import { Patient } from 'models/user';
import { formatMainName } from 'utils/formatUserInfo';
import { playSound } from 'utils/sounds';
import { addMessageToNotificationsList } from 'store/notifications';
import { currentChannelSelector } from 'store/messaging/channel';
import { isMessagingOpenSelector } from 'store/patient';
import {
  askBrowserNotificationPemission,
  showBrowserNotification,
  BrowserNotification,
} from 'utils/browserNotifications';
import messageNotificationSound from 'assets/sounds/message-notification.mp3';

const useChatNotifications = () => {
  const dispatch = useAppDispatch();
  const sdk = useAppSelector(sdkSelector);
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const currentChannel = useAppSelector(currentChannelSelector);
  const isMessagingOpen = useAppSelector(isMessagingOpenSelector);

  const [fetchPatientsAssignToClinician, { data: patientsAssignToClinician }] =
    registryApi.endpoints.fetchPatientsAssignToClinician.useLazyQuery();

  const handlePatientsAssignToClinicianForNotifications = useCallback(() => {
    if (tokenParsedSelector?.extension_AllHealthID || tokenParsedSelector?.sub) {
      fetchPatientsAssignToClinician({
        userId: tokenParsedSelector?.extension_AllHealthID ?? tokenParsedSelector?.sub,
      });
    }
  }, [fetchPatientsAssignToClinician, tokenParsedSelector]);

  useEffect(() => {
    if (!patientsAssignToClinician || !sdk) {
      return;
    }

    const messageReceiverId = v4();
    if (sdk.ChannelHandler) {
      const channelHandler = new sdk.ChannelHandler();

      channelHandler.onMessageReceived = (
        channel: Sb.GroupChannel | Sb.OpenChannel,
        message: Sb.UserMessage | Sb.FileMessage | Sb.AdminMessage
      ) => {
        if (!isUserOrFileMessage(message)) {
          return;
        }

        const patientAssigned = patientsAssignToClinician.find(
          (patient) => patient.id === message?.sender?.userId
        );
        if (!patientAssigned) {
          return;
        }

        const isMessageFromCurrentChannel =
          currentChannel && channel.isGroupChannel() && channel.isEqual(currentChannel);

        if (
          document.visibilityState === 'visible' &&
          isMessagingOpen &&
          isMessageFromCurrentChannel
        ) {
          // do not notify about messages in the currently active channel,
          // unless the browser tab \ window is hidden, or the chat is closed
          return;
        }

        const newNotification = notificationFromMessage(message, channel, patientAssigned);
        dispatch(addMessageToNotificationsList(newNotification));
        showBrowserNotification(browserNotificationFromNotification(newNotification));
        playSound(messageNotificationSound);
      };

      sdk.addChannelHandler(messageReceiverId, channelHandler);
    }

    return () => {
      if (sdk.removeChannelHandler) {
        sdk.removeChannelHandler(messageReceiverId);
      }
    };
  }, [patientsAssignToClinician, sdk, isMessagingOpen, currentChannel, dispatch]);

  useEffect(() => {
    if (!patientsAssignToClinician || !sdk) {
      return;
    }

    fetchAndDispatchUnreadMessages(sdk, patientsAssignToClinician, dispatch);
  }, [sdk, patientsAssignToClinician, dispatch]);

  useEffect(() => {
    askBrowserNotificationPemission();
  }, []);

  return {
    handlePatientsAssignToClinicianForNotifications,
  };
};

export default useChatNotifications;

function fetchAndDispatchUnreadMessages(
  sb: SendBirdInstance,
  patients: Patient[],
  dispatch: AppDispatch
) {
  const patientsByChannelUrl = Object.fromEntries<Patient>(
    patients
      .filter((patient) => patient.sendbirdInfo?.channel != null)
      .map((patient) => [patient.sendbirdInfo.channel!, patient])
  );

  if (isEmpty(patientsByChannelUrl)) {
    return [];
  }

  const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
  channelListQuery.includeEmpty = false;
  channelListQuery.channelUrlsFilter = Object.keys(patientsByChannelUrl);
  channelListQuery.order = 'latest_last_message';
  channelListQuery.limit = 100;

  channelListQuery.next(function (groupChannels, error) {
    if (error) {
      console.log('Error fetching messaging channels', error);
      return;
    }

    groupChannels
      .filter((channel) => channel.unreadMessageCount > 0)
      .forEach((channel) => {
        const params = new sb.MessageListParams();
        params.isInclusive = false;
        params.prevResultSize = 0;
        params.nextResultSize = channel.unreadMessageCount;

        channel.getMessagesByTimestamp(channel.myLastRead, params, (messages, error) => {
          if (error) {
            console.log('Error fetching unread messages', error);
            return;
          }

          for (const message of messages) {
            if (message.isUserMessage() || message.isFileMessage()) {
              dispatch(
                addMessageToNotificationsList(
                  notificationFromMessage(message, channel, patientsByChannelUrl[channel.url])
                )
              );
            }
          }
        });
      });
  });
}

function notificationFromMessage(
  message: Sb.UserMessage | Sb.FileMessage,
  channel: GroupChannel | OpenChannel,
  patient?: Patient
): Notification {
  const channelData = channel.data && JSON.parse(channel.data);
  let result: Notification;
  if (message.isUserMessage()) {
    result = {
      id: String(message.messageId),
      title: patient ? formatMainName(patient.personalInfo) : '',
      image: patient?.personalInfo.photoUrl ?? null,
      description: `${message.message || ''}`,
      patientId: message.sender?.userId,
      time: format(message.createdAt, 'h:mmaa'),
      ogMetaData: message.ogMetaData ?? null,
      chatUserId: channelData?.userId || message.sender?.userId,
      group: channelData?.group,
    };
  } else if (message.isFileMessage()) {
    result = {
      id: String(message.messageId),
      title: patient ? formatMainName(patient.personalInfo) : '',
      image: patient?.personalInfo.photoUrl ?? null,
      patientId: message.sender?.userId,
      time: format(message.createdAt, 'h:mmaa'),
      isFile: message.type ? true : false,
      fileName: message.name ?? null,
      type: message.type ?? undefined,
      plainUrl: message.url ?? null,
      ogMetaData: message.ogMetaData ?? null,
      chatUserId: channelData?.userId || message.sender?.userId,
      group: channelData?.group,
    };
  } else {
    throw new TypeError('Only UserMessage or FileMessage can be turned into notification');
  }
  return result;
}

function browserNotificationFromNotification(appNotification: Notification): BrowserNotification {
  return {
    title: appNotification.title,
    body: (appNotification.isFile ? 'Sent a file' : appNotification.description) ?? '',
    icon: (appNotification.isFile ? appNotification.plainUrl : appNotification.image) ?? undefined,
  };
}

function isUserOrFileMessage(
  message: Sb.UserMessage | Sb.FileMessage | Sb.AdminMessage
): message is Sb.UserMessage | Sb.FileMessage {
  return message.isUserMessage() || message.isFileMessage();
}
